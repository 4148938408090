import { reactive } from 'vue-demi';
import { IAnamneseCharlyQuestion, ICharlyAnamneseConfig } from '../../../../types';

export const anamneseCharlyConfigStore = reactive({
  state: {
    charlyAnamneseConfig: null as null | ICharlyAnamneseConfig,
    get affixes(): string[] {
      return this.charlyAnamneseConfig?.affixes.map(c => c.nameAffix) || [];
    },
    get countries(): string[] {
      if (!this.charlyAnamneseConfig || !this.charlyAnamneseConfig.countries) {
        return [];
      }
      return Array.from(new Set(this.charlyAnamneseConfig.countries.map(c => c.country).filter(c => !!c)));
    },
    get peerages(): string[] {
      return this.charlyAnamneseConfig?.peerages.map(c => c.peerage) || [];
    },
    get titles(): string[] {
      return this.charlyAnamneseConfig?.titles.map(c => c.title) || [];
    },
    get charlyQuestions(): IAnamneseCharlyQuestion[] {
      return this.charlyAnamneseConfig?.questions || [];
    },
  },
  setCharlyAnamneseConfig(charlyAnamneseConfig: ICharlyAnamneseConfig) {
    this.state.charlyAnamneseConfig = charlyAnamneseConfig;
  },
  getCharlyQuestionById(id: number) {
    return this.state.charlyQuestions.find(q => q.questionID === id);
  },
});

// @ts-ignore
window.anamneseCharlyConfigStore = anamneseCharlyConfigStore;
