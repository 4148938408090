import { isEmpty, uniq } from 'lodash';
import { reactive, set as VueSet } from 'vue-demi';
import {
  CharlyContactInfoState,
  CharlyContactInfoType,
  charlyGenderValueSet,
  ICharlyPatientDetails,
  IQuestionnairePatientInfoFieldConfig,
} from '../../../../types';
import { roseNanoidNumeric } from '../../nanoid';
import { anamneseCharlyConfigStore } from './anamneseCharlyConfigStore';

export interface IAnamnesePatientDetailsField {
  /** unique id, since field property is not unique for contacts */
  fieldId: string;
  field: keyof ICharlyPatientDetails;
  label: string;
  placeholder?: string;
  type: 'text' | 'date' | 'valueSet' | 'contact';
  valueSet?: readonly ({ key: string; value: string } | string)[];
  contactState?: CharlyContactInfoState;
  contactType?: CharlyContactInfoType;
  inputType?: string;
  pattern?: string;
  fullWidth?: boolean;
  valueSetAllowEmpty?: boolean;
  optional?: boolean;
}

export const anamnesePatientStore = createPatientStore();
export const anamnesePreviousPatientStore = createPatientStore();
// @ts-ignore
window.anamnesePreviousPatientStore = anamnesePreviousPatientStore;

export const anamesePatientPrivateEmailField: IAnamnesePatientDetailsField = {
  fieldId: 'contacts.email',
  field: 'contacts',
  label: 'Email',
  type: 'contact',
  contactState: 'PRIVATE',
  contactType: 'EMAIL',
  inputType: 'email',
};

function createPatientStore() {
  return reactive({
    state: {
      // charly patient details
      initialPatientDetails: null as ICharlyPatientDetails | null,
      // charly patient details to be edited
      patientDetails: null as ICharlyPatientDetails | null,
      highlightField: null as null | any,
      get availableFields(): IAnamnesePatientDetailsField[] {
        return [
          // person
          {
            fieldId: 'title',
            field: 'title',
            label: 'Titel',
            type: 'valueSet',
            valueSet: anamneseCharlyConfigStore.state.titles,
            valueSetAllowEmpty: true,
            fullWidth: true,
          },
          { fieldId: 'firstName', field: 'firstName', label: 'Vorname', type: 'text' },
          { fieldId: 'lastName', field: 'lastName', label: 'Nachname', type: 'text' },
          { fieldId: 'birthday', field: 'birthday', label: 'Geburtstag', type: 'date' },
          {
            fieldId: 'gender',
            field: 'gender',
            label: 'Geschlecht',
            type: 'valueSet',
            valueSet: charlyGenderValueSet,
          },
          // adresse
          {
            fieldId: 'street',
            field: 'street',
            label: 'Straße, Hausnummer',
            type: 'text',
            placeholder: 'z.B. Musterstraße 123',
            fullWidth: true,
          },
          {
            fieldId: 'zip',
            field: 'zip',
            label: 'Postleitzahl',
            type: 'text',
            inputType: 'number',
            pattern: '[0-9]*',
          },
          { fieldId: 'city', field: 'city', label: 'Stadt', type: 'text' },
          {
            fieldId: 'country',
            field: 'country',
            label: 'Land',
            type: 'valueSet',
            valueSet: anamneseCharlyConfigStore.state.countries,
          },
          { fieldId: 'birthplace', field: 'birthplace', label: 'Geburtsort', type: 'text' },
          {
            fieldId: 'contacts.handy',
            field: 'contacts',
            label: 'Mobilfunk',
            type: 'contact',
            contactState: 'PRIVATE',
            contactType: 'MOBILE',
            inputType: 'tel',
          },
          {
            fieldId: 'contacts.telefon',
            field: 'contacts',
            label: 'Festnetz',
            type: 'contact',
            contactState: 'PRIVATE',
            contactType: 'PHONE',
            inputType: 'tel',
          },
          anamesePatientPrivateEmailField,
        ];
      },
      get questionnaireFields(): (IAnamnesePatientDetailsField & { optional: boolean })[] {
        return this.availableFields
          .filter(field => this.fieldConfig?.[field.fieldId]?.hideField !== true)
          .map(field => ({
            ...field,
            optional: field.optional || this.fieldConfig?.[field.fieldId]?.optional === true,
          }));
      },
      fieldConfig: null as null | IQuestionnairePatientInfoFieldConfig,
    },
    setPatientDetails(patientDetails: ICharlyPatientDetails | undefined | null) {
      this.state.patientDetails = patientDetails || null;
    },
    setInitialPatientDetails(patientDetails: ICharlyPatientDetails) {
      this.state.initialPatientDetails = patientDetails;
    },
    setFieldConfig(fieldConfig: IQuestionnairePatientInfoFieldConfig | null) {
      this.state.fieldConfig = fieldConfig;
    },
    getPatientDetails() {
      return this.state.patientDetails;
    },
    clearPatientDetails() {
      this.state.patientDetails = null;
      this.state.highlightField = null;
    },
    hightlightValidationError() {
      this.state.highlightField = this.firstInvalidField();
    },
    getFieldIdValue(fieldId: string, overridePatientDetails?: ICharlyPatientDetails) {
      let field = this.state.questionnaireFields.find(f => f.fieldId === fieldId);
      if (field) {
        return this.getFieldValue(field, overridePatientDetails);
      }
    },
    getFieldValue(field: IAnamnesePatientDetailsField, overridePatientDetails?: ICharlyPatientDetails) {
      let patDetails = overridePatientDetails || this.state.patientDetails;

      if (field.type === 'contact') {
        let contact = patDetails?.contacts?.find(c => c.state === field.contactState && c.type === field.contactType);
        return contact?.value;
      }

      return patDetails?.[field.field] as string;
    },
    setFieldIdValue(fieldId: string, value: any) {
      let field = this.state.questionnaireFields.find(f => f.fieldId === fieldId);
      if (field) {
        this.setFieldValue(field, value);
      }
    },
    setFieldValue(field: IAnamnesePatientDetailsField, value: any) {
      this.state.patientDetails = this.state.patientDetails || ({} as ICharlyPatientDetails);

      if (field.type === 'contact') {
        let contacts = this.state.patientDetails?.contacts || [];
        let contact = contacts.find(c => c.state === field.contactState && c.type === field.contactType);
        if (contact) {
          contact.value = value;
        } else {
          contacts.push({
            id: roseNanoidNumeric(),
            state: field.contactState!,
            type: field.contactType!,
            value,
          });
        }
        VueSet(this.state.patientDetails, field.field, contacts);
      } else {
        VueSet(this.state.patientDetails, field.field, value);
      }
    },
    hasFieldValidAnswer(field: IAnamnesePatientDetailsField & { optional: boolean }) {
      let fieldValue = this.getFieldValue(field);

      if (isStringValidCharlyInput(fieldValue).valid === false) {
        return false;
      }

      if (field.optional || field.valueSetAllowEmpty) {
        return true;
      }

      let telefoneFieldIds = ['contacts.handy', 'contacts.telefon'];
      if (telefoneFieldIds.includes(field.fieldId)) {
        return this.state.questionnaireFields
          .filter(f => telefoneFieldIds.includes(f.fieldId))
          .some(f => this.getFieldValue(f));
      }

      if (!fieldValue) {
        return false;
      }

      return true;
    },
    firstInvalidField() {
      return this.state.questionnaireFields.find(field => this.hasFieldValidAnswer(field) === false);
    },
    hasValidPatientDetails() {
      return !this.firstInvalidField();
    },
  });
}

export function isStringValidCharlyInput(input?: string) {
  // only allow common characters, return error message with invalid characters
  const invalidChars = (input || '').match(/[^a-zA-Z0-9äöüÄÖÜß\-_().:\s%!/<>"'&,+=*?;@#$§€^éèàùâêîôûçëï]/g);
  // charly converts these to "_":
  // ϋ

  if (!isEmpty(invalidChars)) {
    // make invalidChars unique
    const uniqInvalidChars = uniq(invalidChars);

    return { valid: false, errorMsg: `Ungültige Zeichen: ${uniqInvalidChars.join(', ')}` };
  }
  return { valid: true };
}
